/* Hover effect for arrows and label */
.react-calendar__navigation button:hover,
.react-calendar__navigation .react-calendar__navigation__label:hover {
  color: black;
  padding: 2px;
  border-radius: 4px; /* Optional for rounded corners */
}
/* Optional: Additional button spacing */
.react-calendar__navigation button {
  margin: 0 5px;
}