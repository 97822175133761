body {
  margin: 0;
  font-family: roboro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px; /* Standard base size */
}

/* Header tags (H1 to H6) */
h1 {
  font-size: 32px; /* Main heading */
  font-weight: bold;
  margin-bottom: 16px;
}

h2 {
  font-size: 28px; /* Subheading */
  font-weight: bold;
  margin-bottom: 14px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

h6 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
}

/* Paragraph */
p {
  font-size: 16px; /* Default paragraph size */
  margin-bottom: 16px;
}

/* Small text */
small {
  font-size: 14px;
}

/* Span or inline text */
span, a {
  font-size: inherit; /* Inherit the font size from parent */
}

/* List items */
li {
  font-size: 16px; /* Standard list items */
}

/* Code elements */
code {
  font-size: 14px;
  font-family: 'Courier New', monospace;
}

/* Input and button elements */
input, button, select, textarea {
  font-size: 16px; /* Standardize form elements */
}

/* Default label */
label {
  font-size: 16px;
}

/* For larger text sections */
.large-text {
  font-size: 20px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  padding: 1rem;
}


.pointer:hover {
  cursor: pointer;
}

.custom-switch .form-check-input {
  width: 48px;
  height: 24px;
}


.th-trans th {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: bold;
}

.custom-cell {
  height: 125px;
  width: 150px;
}

.hide-caret::after {
  display: none !important;
}

.primary-hover:hover {
  background-color: #5e17eb !important;
  color: white
}

.success-hover:hover {
  background-color: #14A44D !important;
  color: white
}

.danger-hover:hover {
  background-color: #DC4C64 !important;
  color: white
}

.emp-card-size-limit {
  min-width: 300px;
  max-width: 300px;
  margin: 0 auto
}

.min-width-table th {
  min-width: 200px;
}