@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
    body {
        margin: 0 !important;
    }
}

.main-container {
    --ckeditor5-preview-sidebar-width: 270px;
    --ckeditor5-preview-height: 700px;
    font-family: 'Lato';
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.main-container .presence {
    margin-bottom: 8px;
}

.ck-content {
    font-family: 'Lato';
    line-height: 1.6;
    word-break: break-word;
}

.editor-container__editor-wrapper {
    display: flex;
    width: fit-content;
}

.editor-container_document-editor {
    border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
    display: flex;
    position: relative;
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
    flex-grow: 1;
    width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__menu-bar > .ck.ck-menu-bar {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
    max-height: var(--ckeditor5-preview-height);
    min-height: var(--ckeditor5-preview-height);
    overflow-y: scroll;
    background: var(--ck-color-base-foreground);
}

.editor-container_document-editor .editor-container__editor {
    margin-top: 28px;
    margin-bottom: 28px;
    height: 100%;
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    min-width: calc(210mm + 2px);
    max-width: calc(210mm + 2px);
    min-height: 297mm;
    height: fit-content;
    padding: 20mm 12mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
    margin-left: 72px;
    margin-right: 72px;
}

.editor-container_include-outline .editor-container__editor .ck.ck-editor__editable {
    margin-left: 0;
}

.editor-container_include-annotations .editor-container__editor .ck.ck-editor__editable {
    margin-right: 0;
}

.editor-container__sidebar {
    min-width: var(--ckeditor5-preview-sidebar-width);
    max-width: var(--ckeditor5-preview-sidebar-width);
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
}

.revision-history {
    display: none;
}

.revision-history__wrapper {
    display: flex;
}

.revision-history__wrapper .ck.ck-editor {
    margin: 0;
    width: 795px;
}

.revision-history__wrapper .revision-history__sidebar {
    border: 1px solid var(--ck-color-base-border);
    border-left: 0;
    width: var(--ckeditor5-preview-sidebar-width);
    min-height: 100%;
}

.revision-history__wrapper .revision-history__sidebar .ck-revision-history-sidebar {
    height: 100%;
}

.ck-content .table thead {
    display: table-row-group;
}

.ck-content .table tr {
    break-inside: avoid;
    break-after: auto;
}

/*Dark Mode*/
/* Invert the whole container, similar to Dark Reader */
/* Dark mode container: set dark background and base text color */

.dark-mode div {
    background-color: transparent !important;
    color: #e0e0e0 !important;
}

/* Ensure all child elements inherit the dark mode colors */
.dark-mode *,
.dark-mode *::before,
.dark-mode *::after {
    background-color: transparent;
    color: inherit;
}

/* Override CKEditor content specifically */
.dark-mode .ck-content {
    background-color: #121212 !important;
    color: #e0e0e0 !important;
    border-color: #444444; /* adjust borders if needed */
}

/* Headings: make them brighter */
.dark-mode .ck-content h1,
.dark-mode .ck-content h2,
.dark-mode .ck-content h3,
.dark-mode .ck-content h4,
.dark-mode .ck-content h5,
.dark-mode .ck-content h6 {
    color: #ffffff !important;
}

/* Links: use a distinct color */
.dark-mode a {
    color: #66bfff !important;
}

/* If you want images to display normally (not inverted) */
.dark-mode img,
.dark-mode video,
.dark-mode iframe,
.dark-mode svg {
    filter: none !important;
}

/* DARK MODE UI OVERRIDES */

/* Make the presence container dark with white text. */
.dark-mode .presence {
    color: #fff !important;
}

/* Toolbar and menu bar background + text/icons */
.dark-mode .ck.ck-toolbar,
.dark-mode .ck.ck-menu-bar {
    background-color: #1f1f1f !important; /* or #121212 */
    color: #fff !important;
}

/* Ensure buttons and dropdowns use white text/icons */
.dark-mode .ck.ck-toolbar .ck-button,
.dark-mode .ck.ck-toolbar .ck-dropdown,
.dark-mode .ck.ck-menu-bar .ck-button {
    color: #fff !important;
    background-color: #1f1f1f !important;
    border: none !important;
}

/* Force icons (SVG) to display in white */
.dark-mode .ck.ck-toolbar .ck-button .ck-icon,
.dark-mode .ck.ck-toolbar .ck-dropdown .ck-icon,
.dark-mode .ck.ck-menu-bar .ck-button .ck-icon {
    fill: #fff !important;
}

/* Optionally, hover/focus states for toolbar buttons */
.dark-mode .ck.ck-toolbar .ck-button:hover,
.dark-mode .ck.ck-toolbar .ck-button:focus {
    background-color: #333 !important; /* A slightly lighter dark */
}

/* Let the color grid tiles display their inline styles */
.dark-mode .ck-color-grid__tile,
.dark-mode .ck-color-grid__tile * {
    background-color: unset !important;
    color: unset !important;
    filter: none !important;
}

/* Disable Bootstrap theme for CKEditor container */
[data-bs-theme="dark"] .editor-container_document-editor,
[data-bs-theme="dark"] .ck-content {
    /* Reset background and text color */
    background-color: initial !important;
    color: initial !important;
}

/* Alternatively, you can set your desired dark mode styles explicitly */
[data-bs-theme="dark"] .editor-container_document-editor,
[data-bs-theme="dark"] .ck-content {
    background-color: #ffffff !important;
    color: #121212 !important;
}

@media print {
    .doc-watermark {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.1;
        z-index: 1000;
        width: 400px;  /* adjust as needed */
        height: 300px; /* adjust as needed */
        pointer-events: none;
    }
}

/* For screen preview, if needed */
.doc-watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    z-index: 1000;
    width: 400px;
    height: 300px;
    pointer-events: none;
}
